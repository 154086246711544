.WebsitesContainer{
    color:white;
    margin-top: 2%;
    background-image: url('julian-hochgesang-psGV5KhidlY-unsplash.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
}


.WebsitesContainer h1{
    font-size: 50px;
}

.WebsitesContainer h2{
    text-align: left;
    margin-left: 10%;
    font-size: 40px;
}


.WebsitesGrid{
    display: grid;
    list-style-type: none;
    width: 100%;
    margin-top: 5%;
    aspect-ratio: 1;
}



.WebsitesGrid li img{
    width: 500px;
    transition: transform .7s ease-in-out;
    border: 4px solid rgba(255, 255, 255, 0.23);
    border-radius: 20px;
}


.WebsitesGrid li:nth-child(1){
    grid-column-start: 0;
    grid-column-end:3 ;
    grid-row-start: 0;
    grid-row-end: 2;
}



.WebsitesGrid li:nth-child(2){
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 5;
}



.WebsitesGrid li:nth-child(2) img{
    height: 500px;
    width: 350px;
}


.WebsitesGrid li:nth-child(3){
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 5;
}




.WebsitesGrid li:nth-child(4){
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 7;
}




.WebsitesGrid li:nth-child(5){
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 6;
    grid-row-end: 8;
}




.Divider{
    color: white;

}

.Divider h3, h4{
    font-size: 50px;
    margin-top: 2%;
    padding-bottom: 2%;
}

.HackathonsContainer{
    background-image: url('markus-spiske-iar-afB0QQw-unsplash.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    padding-top: 10%;
}

.HackathonsList{
    display:flex;
    list-style-type: none;
    justify-content: space-around;
    padding-bottom: 10%;
}

.HackathonsList li{

}


.HackathonsList li img{
    width: 450px;
    transition: transform .7s ease-in-out;
    border: 4px solid rgba(255, 255, 255, 0.23);
    border-radius: 20px;
}

.HackathonsList li:nth-child(1) img:hover{
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(-405deg) rotateZ(0deg)
    translateX(0px) translateY(0px) translateZ(0px) skewX(10deg) skewY(0deg);
    box-shadow: 0 4px 30px rgba(255, 255, 255, 1);
}

.HackathonsList li:nth-child(2) img:hover{
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(405deg) rotateZ(0deg)
    translateX(0px) translateY(0px) translateZ(0px) skewX(10deg) skewY(0deg);
    box-shadow: 0 4px 30px rgba(255, 255, 255, 1);
}

.UniversityContainer{
    color:white;
    background-image: url('shurui-chen-UZL8qUMCx0Y-unsplash.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    padding-top: 10%;
}

.UniversityGrid{
    display: grid;
    list-style-type: none;
    width: 100%;
    margin-top: 5%;
    aspect-ratio: 1;
}

.UniversityGrid li:hover{
    cursor: pointer;
}


.UniversityGrid  li img{
    width: 500px;
    transition: transform .7s ease-in-out;
    border: 4px solid rgba(255, 255, 255, 0.23);
    border-radius: 20px;
}


.UniversityGrid li:nth-child(1){
    grid-column-start: 0;
    grid-column-end:3 ;
    grid-row-start: 0;
    grid-row-end: 2;
}





.UniversityGrid li:nth-child(2){
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 5;
}



.UniversityGrid li:nth-child(2) img{
    height: 500px!important;
    width: 350px;
}


.UniversityGrid li:nth-child(3){
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 5;
}




.UniversityGrid li:nth-child(4){
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 7;
}




.UniversityGrid li:nth-child(5){
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 6;
    grid-row-end: 8;
}







@media screen and (max-width:1000px){
    .WebsitesGrid li img{
        width: 350px;
    }

    .WebsitesGrid li:nth-child(2) img{
        height: 400px;
        width: 250px;
    }


    .HackathonsList li img{
        width: 350px;
    }

    .UniversityGrid  li img{
        width: 350px;
    }

    .UniversityGrid  li:nth-child(2) img{
        height: 400px;
        width: 250px;
    }
}

@media screen and (max-width:800px){

    .WebsitesContainer{
        padding-top: 5%;
    }

    .WebsitesContainer h1{
        display: none;
    }

    .WebsitesContainer h2{
        text-align: center;
    }

    .WebsitesGrid{
        display: block;
        padding-bottom: 5%;
    }

    .WebsitesGrid li{
        margin-top: 10%;
    }

    .WebsitesGrid li:nth-child(2) img{
        width: 350px;
        height:195px;
    }

    .HackathonsList{
        display: block;
    }

    .HackathonsList li{
        margin-top: 10%;
    }

    .Divider h3, h4{
        font-size: 40px;
    }

    .UniversityGrid{
        display: block;
    }

    .UniversityGrid{
        padding-bottom: 10%;
    }

    .UniversityGrid li{
        margin-top: 10%;
    }

    .UniversityGrid  li:nth-child(2) img{
        width: 350px;
        height:195px!important;
    }
}


.tilt img:hover{
    box-shadow: 0 4px 30px rgba(255, 255, 255, 1);
}



