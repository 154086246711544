.HomePassionContainer h1{
    text-align: left;
    padding-bottom: 2%;
    color: white;
    font-size: 4vw;
}


.HomePassionContainer{
    margin-top: 40%;
    width: 45%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border: 1px solid rgba(255, 255, 255, 0.23);
    text-align: justify;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 2.2vw;
    color: white;
}

.HomePassionContainer h2{
    font-size: 2.2vw;
}


@media screen and (max-width: 600px) {
    .HomePassionContainer {
        margin-top: 40%;
        width: 100%;
        font-size: 3vw;
        float: none;
    }

    .HomePassionContainer  h1{
        text-align: center;
        padding-bottom: 5%;
        font-size:5vw;
    }
}