
.ContactContainer{
    padding-top: 5%;
    position: relative;
}

.MessageTextArea{
    width: 100%;
    height: 200px;
}

.submit_button{
    font-size: 30px;
}

.Header1, .Header2, .submit_button {
    position: relative;
}

.Header1{
}

.Header2{
    color: white;
    font-size: 20px;
    margin-top: 5%;
    padding-right: 20%;
    padding-left: 20%;
}


@media screen and (max-width:800px){

    .submit_button{
        font-size: 20px;
    }

    .Header2{
        padding-right: 5%;
        padding-left: 5%;
        font-size: 15px;
        padding-bottom: 10%;
    }

}


.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("markus-spiske-iar-afB0QQw-unsplash.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0; /* put the image behind other content */
    animation: fadeInOut 4s infinite;
    background-position: center;
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }   /* Hidden at the start and end */
    50%     { opacity: 1; }   /* Fully visible at the halfway point */
}

