.HomeEducationContainer h1{
    text-align: left;
    padding-bottom: 2%;
    color: white;
    font-size: 4vw;
}


.HomeEducationContainer {
    margin-top: 20%;
    width: 45%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border: 1px solid rgba(255, 255, 255, 0.23);
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 2.2vw;
    color: white;
    float: right;
}


.ConcordiaEducation h1{
    font-size: 3vw;
    margin-top: 10%;
}

.ConcordiaEducation ul li{
    font-size: 2.2vw;
    text-align: left;
    margin-top: 10%;
}


.ConcordiaEducation h2{
    font-size: 2.5vw;
    margin-top: 5%;
    padding-bottom: 5%;
    text-align: left;
}


.M9Education h1{
    font-size: 2.5vw;
    margin-top: 10%;
}

.M9Education h2{
    font-size: 2.5vw;
    margin-top: 5%;
    padding-bottom: 5%;
    text-align: left;
}

.M9Education ul li{
    font-size: 2.2vw;
    text-align: left;
    margin-top: 10%;
}


@media screen and (max-width: 600px) {
    .HomeEducationContainer{
        margin-top: 40%;
        width: 100%;
        font-size: 3vw;
        float: none;
    }

    .HomeEducationContainer h1{
        text-align: center;
        padding-bottom: 5%;
        font-size:6vw;
    }

    .ConcordiaEducation h1{
        font-size: 5vw;
    }

    .M9Education h1{
        font-size: 5vw;
    }

}
