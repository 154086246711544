
.WorkHeader{
    text-align: left;
    padding-bottom: 2%;
    color: white;
    font-size: 8vw;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 2s ease-out,transform 2s ease-out;;
}

.WorkHeader.visible {
    opacity: 1;
    transform: translateY(0);

}


.HomeWorkContainer{
    margin-top: 40%;
    text-align: justify;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 2.2vw;
    color: white;
}

.HomeWorkContainer ul{
    list-style-type: none;
}


@media screen and (max-width: 600px) {
    .WorkHeader{
        text-align: center;
        padding-bottom: 5%;
        font-size: 10vw;
    }

    .HomeWorkContainer{
        padding-left: 0;
        padding-right: 0;
    }

    .HomeWorkContainer ul{
        padding: 0;
    }

}