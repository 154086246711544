
.MainContainer{
    /*grid*/
    display: none;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 10%;
    overflow: auto;
    scroll-behavior: smooth;
    font-family: 'Roboto Condensed', sans-serif!important;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(164, 5, 184, 0.2));

}

.FirstScrollEvent{
    scroll-behavior: smooth;
    display: grid;

}

.progress-bar-container{
    position: absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#progress-bar{
    width: 30%;
    margin-top: 0.5%;
    height: 2%;
}

label{
    color: white;
    font-size: 2rem;
}


@media screen and (max-width: 600px) {
    .MainContainer{
    }

    #progress-bar{
        width: 70%;
    }
}




