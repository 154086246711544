.WorkPetroContainer h1{
    text-align: left;
    padding-bottom: 10%;
    color: white;
    font-size: 4vw;
}


.WorkPetroContainer{
    margin-top: 10%;
    padding-top: 2%;
    width: 55%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.23);
    text-align: justify;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 2.2vw;
    color: white;
}

@media screen and (max-width: 600px) {
    .WorkPetroContainer{
        margin-top: 20%;
        width: 100%;
        font-size: 3vw;
        float: none;
        padding-right: 5%;
        padding-left: 5%;
    }

    .WorkPetroContainer h1{
        text-align: center;
        padding-bottom: 5%;
        font-size:5vw;
    }
}