.StoryContainer{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border: 1px solid rgba(255, 255, 255, 0.23);
    text-align: justify;
    padding-bottom: 2%;
    font-size: 20px;
    color: white;
}

.StoryContainer img{
    width: 100%;
    height: 450px;
    display: block;      /* Remove any unwanted space below the image */
    max-width: 100%;
}

.StoryContainer h1{
    margin-left: 2%;
}

.StoryContainer p{
    padding-left: 2%;
}