.Intro h1{
    color: white;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    font-size: 10vw;
    animation-delay: 4s;
    animation:
            typing 2s steps(50,end),
            blink-caret .75s step-end infinite,
            remove-blink-caret forwards 8s;
    border-right: .15em solid white;
    white-space: nowrap;
    width: max-content;
}

.Intro{
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border: 1px solid rgba(255, 255, 255, 0.23);
    padding-bottom: 2%;
    font-size: 2.2vw;
    color: white;
    text-align: center;
    padding-left: 10%;
}

.Intro h2{
    animation-delay: 2s !important;
    animation: 2s fade forwards;
    opacity: 0;
    font-size: 10vw;
    margin-top: 10%;
}


@keyframes typing {
    from { width: 0 }
    to { width: 70% }
}

@keyframes remove-blink-caret {
    0%{

    }
    100%{
        border: none;
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: white }
    50% { border-color: black; }
}

@keyframes fade {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
