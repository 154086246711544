.button {
    background-color: silver;
    color: black;
    font-size: 3vw;
    border-radius: 100px;
    padding-right: 7.5%;
    padding-left: 7.5%;
    padding-top: 1%;
    padding-bottom: 1%;
    border: solid 2px white;
    box-shadow: rgba(200, 200, 200, 0.59) 0px 0px 22px 4px;
    transition: background-color 0.5s ease;
}

.button:hover {
    background-color: black;
    color: white;
}