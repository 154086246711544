.togglerContainer{
    width: 100%;
    height: 100px;
    z-index: 100;
}

.NavLink{
    color:white!important;
    font-size: 40px;
}

.NavLink:hover{
    color: darkviolet !important;
    font-size: 50px;
}

.ModalClosebutt{
    font-size: 30px;
    border: none;
    color: white;
    background: none;
}

.custom-toggler{
    margin-top: 2%;
    float: left;
    margin-left: 10%;
    width: 60px; /* Adjust as necessary */
    height: 60px; /* Adjust as necessary */
    border: 1px solid white;

}



.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


.custom-toggler:hover{
    color: white;
}

@media screen and (max-width: 576px){
    .NavLink{
        font-size: 25px;
        width: 100%;
    }

    .NavLink:hover{
        font-size: 30px;
    }

}





