.RealIntro{
    margin-top: 20%;
    width: 45%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border: 1px solid rgba(255, 255, 255, 0.23);
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    font-size: 2.2vw;
    color: white;
    float: right;
    text-align: justify;
}


@media screen and (max-width: 600px) {
    .RealIntro{
        margin-top: 90%;
        width: 100%;
        font-size: 3vw;
    }
}

